.style_arrow__OseBE{width:40px;height:20px;display:flex}.style_arrow__OseBE:before{content:"";position:absolute;width:8px;height:8px;bottom:0;border-color:#0d0d0d;border-color:#fff;border-style:solid;border-width:1.4px 0 0 1.4px;transition:border-color .2s linear,transform .4s cubic-bezier(0.19, 1, 0.22, 1)}.style_arrow-left__NWmqa{justify-content:end}.style_arrow-left__NWmqa:before{left:0;border-top-width:var(--arrow-width);border-left-width:var(--arrow-width);animation:style_arrow__OseBE 1.5s ease-in-out infinite}@keyframes style_arrow__OseBE{0%{transform:translate(0, -50%) rotate(-45deg)}50%{transform:translate(-3px, -50%) rotate(-45deg)}100%{transform:translate(0, -50%) rotate(-45deg)}}.style_arrow-right__ac2X0{justify-content:start}.style_arrow-right__ac2X0:before{right:0;border-top-width:var(--arrow-width);border-left-width:var(--arrow-width);animation:style_arrow-right__ac2X0 1.5s ease-in-out infinite}@keyframes style_arrow-right__ac2X0{0%{transform:translate(0, -50%) scale(-1) rotate(-45deg)}50%{transform:translate(3px, -50%) scale(-1) rotate(-45deg)}100%{transform:translate(0, -50%) scale(-1) rotate(-45deg)}}.style_wrapper__beUEC{width:120px;height:20px;position:absolute;transform:translateX(-50%);left:50%;z-index:1;bottom:30px}.style_indicator__fyIz5{display:block;color:#fff;overflow:hidden;position:relative;height:20px;width:12px}.style_indicator__fyIz5 span{display:block;height:var(--icon-height);width:12px}.style_splash__CsY3E{text-align:right;position:relative}.style_splash__CsY3E:before{content:"/";left:50%;top:60%;position:absolute;margin-left:auto;margin-right:auto;font-size:80%;font-weight:700;transform:translate(-50%, -50%)}.style_swipe__UHGUv{animation:style_swipe__UHGUv 2s linear infinite forwards}@keyframes style_swipe__UHGUv{0%{transform:rotateZ(0deg)}25%{transform:rotateZ(10deg)}75%{transform:rotateZ(-10deg)}100%{transform:rotateZ(0deg)}}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_46981b';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/b6f2eee8808a2bb4-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Mono_46981b';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/bc2003170c651d45-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Mono_46981b';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d43ef4503e5571d0-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_46981b';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/3281a323710833ec-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Mono_46981b';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/684e5662d94c69e1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__IBM_Plex_Mono_Fallback_46981b';src: local("Arial");ascent-override: 76.16%;descent-override: 20.43%;line-gap-override: 0.00%;size-adjust: 134.59%
}.__className_46981b {font-family: '__IBM_Plex_Mono_46981b', '__IBM_Plex_Mono_Fallback_46981b';font-weight: 500;font-style: normal
}

