.arrow {
  width: 40px;
  height: 20px;
  display: flex;

  &:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    bottom: 0;
    border-color: #0d0d0d;
    border-color: #fff;
    border-style: solid;
    border-width: 1.4px 0 0 1.4px;
    transition: border-color 0.2s linear,
      transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  }
}

.arrow-left {
  justify-content: end;

  &:before {
    left: 0;
    border-top-width: var(--arrow-width);
    border-left-width: var(--arrow-width);
    animation: arrow 1.5s ease-in-out infinite;
    @keyframes arrow {
      0% {
        transform: translate(0, -50%) rotate(-45deg);
      }
      50% {
        transform: translate(-3px, -50%) rotate(-45deg);
      }
      100% {
        transform: translate(0, -50%) rotate(-45deg);
      }
    }
  }
}
.arrow-right {
  justify-content: start;

  &:before {
    right: 0;
    border-top-width: var(--arrow-width);
    border-left-width: var(--arrow-width);
    animation: arrow-right 1.5s ease-in-out infinite;
    @keyframes arrow-right {
      0% {
        transform: translate(0, -50%) scale(-1) rotate(-45deg);
      }
      50% {
        transform: translate(3px, -50%) scale(-1) rotate(-45deg);
      }
      100% {
        transform: translate(0, -50%) scale(-1) rotate(-45deg);
      }
    }
  }
}
.wrapper {
  width: 120px;
  height: 20px;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  z-index: 1;
  bottom: 30px;
}
.indicator {
  display: block;
  color: #fff;
  overflow: hidden;
  position: relative;
  height: 20px;
  width: 12px;

  span {
    display: block;
    height: var(--icon-height);
    width: 12px;
  }
}

.splash {
  text-align: right;
  position: relative;

  &:before {
    content: '/';
    left: 50%;
    top: 60%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    font-size: 80%;
    font-weight: 700;
    transform: translate(-50%, -50%);
  }
}

.swipe {
  animation: swipe 2s linear infinite forwards;
  @keyframes swipe {
    0% {
      transform: rotateZ(0deg);
    }
    25% {
      transform: rotateZ(10deg);
    }
    75% {
      transform: rotateZ(-10deg);
    }
    100% {
      transform: rotateZ(0deg);
    }
  }
}
